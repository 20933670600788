import React, { useEffect } from "react"
import { Helmet } from 'react-helmet'
import { Link } from "gatsby"
import * as GitHubCalendar from "github-calendar/dist/github-calendar.min.js"
import { Image } from 'react-bootstrap'
import Layout from "../../components/layout"
import WorkCard from "../../components/workCard.js"
import SEO from "../../components/seo"
import "./index.css"

const IndexPage = ({ pageContext: { workData } }) => {
  useEffect(() => {
    GitHubCalendar(".calendar", "cooperluetje", { responsive: true })
  }, [])
  
  return (
    <Layout
      headerImage={(
        <div class="header-container">
          <div class="header">
            <div>
              <h1 class="h1-text">Hi I'm Cooper</h1>
              <h1 class="h1-text">I'm a Full Stack Developer</h1>
            </div>
            <Image 
              src={require(`../../images/portrait_home.jpg`)}
              className="header-image"
              roundedCircle
            />
          </div>
        </div>
      )}
    >
      <link
        rel="stylesheet"
        href="https://unpkg.com/github-calendar@latest/dist/github-calendar-responsive.css"
      />
      <Helmet>
        <script 
          type="text/javascript" 
          src="https://platform.linkedin.com/badges/js/profile.js" 
          async 
          defer
        ></script>
      </Helmet>
      <SEO title="Home" />
      <div class="d-flex align-items-center mb-4">
        <h2 class="mb-0">Featured Work</h2>
        <Link class="border-left pl-3 ml-3 mt-1" to="/work">View All</Link>
      </div>
      <div className="featured-work">
        {workData.map(item => (
          <WorkCard cardData={item} />
        ))}
      </div>
      <div class="d-flex align-items-center mt-4 mb-4">
        <h2 class="mb-0">GitHub Contributions</h2>
      </div>
      <div className="footer">
        <div class="calendar"></div>
      </div>
    </Layout>
  )
}

export default IndexPage
